import {
  Button,
  ButtonColor,
  type ButtonProps,
  ButtonSize,
} from '@leland-dev/leland-ui-library';
import Link from 'next/link';
import type { FC } from 'react';

type ClosingCtaProps = { buttonProps?: Omit<ButtonProps, 'onClick'> } & (
  | {
      buttonLinkHref?: never;
      onClick: () => void;
    }
  | { buttonLinkHref: string; onClick?: never }
);

export const ClosingCta: FC<ClosingCtaProps> = ({
  buttonLinkHref,
  onClick,
  buttonProps,
}) => {
  return (
    <section className="flex flex-col items-center space-y-6 px-6 py-32 text-center">
      <h2 className="font-condensed text-4xl font-medium">
        Ready to get started?
      </h2>
      <p className="max-w-2xl text-xl text-leland-gray-light">
        A dedicated mentor makes all the difference. Tell us your goals, budget,
        and background, and we&rsquo;ll connect you to the perfect coach.
      </p>
      {buttonLinkHref ? (
        <Link className="w-full sm:w-auto" href={buttonLinkHref}>
          <Button
            label="Join Leland"
            buttonColor={ButtonColor.BLACK}
            size={ButtonSize.LARGE}
            {...buttonProps}
          />
        </Link>
      ) : (
        <div className="w-full sm:w-auto">
          <Button
            label="Join Leland"
            buttonColor={ButtonColor.BLACK}
            size={ButtonSize.LARGE}
            onClick={onClick}
            {...buttonProps}
          />
        </div>
      )}
    </section>
  );
};
